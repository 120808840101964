import axios from "axios";
import configData from "../../config/config.json";

  
import { SEARCH_RESULTS_BATCH_SIZE, loadTableBatches } from "./searchActions";
import { UserSelectionListComponentName } from "../../components/common/selection/UserSelectionList"
import { AccountSelectionListComponentName } from "../../components/common/selection/AccountSelectionList"
import { AccountsControlPanelKey } from "./controlPanelActions";
import { UserListComponetName } from "./userManagerActions";
import { SearchResultsTableComponetName } from "../../components/search/searchResults/SearchResultsTable";
import { SearchResultsDetailsGrantorsGranteesComponetName, SearchResultsDetailsLegalDescriptionsComponetName, SearchResultsDetailsReferenceDocumentsComponetName, SearchResultsDetailsTorrensCertificatesComponetName } from "../../components/search/searchResults/SearchResultsDetails";
import { SearchResultsDetailsTaxpayersComponetName, SearchResultsDetailsReleasesComponetName, SearchResultsDetailsRevisionsComponetName } from "../../components/search/searchResults/TaxLienSearchResultsDetails";
import { PlatSeclectionListComponentName } from "../../components/common/selection/PlatSelectionList"

export const setSelectedPaginationPage = (listType, pageNumber) => {

  let actionType = '';

  switch (listType) {

    case SearchResultsTableComponetName:
      actionType = 'SET_SELECTED_SEARCH_RESULTS_PAGE'
      break;

    case SearchResultsDetailsGrantorsGranteesComponetName:
      actionType = 'SET_SELECTED_DETAILS_GRANTORS_GRANTEES_PAGE'
      break;

    case SearchResultsDetailsLegalDescriptionsComponetName:
      actionType = 'SET_SELECTED_DETAILS_LEGAL_DESCRIPTIONS_PAGE'
      break;

    case SearchResultsDetailsReferenceDocumentsComponetName:
      actionType = 'SET_SELECTED_DETAILS_REFERENCE_DOCUMENTS_PAGE'
      break;

    case SearchResultsDetailsTaxpayersComponetName:
      actionType = 'SET_SELECTED_DETAILS_TAXPAYERS_PAGE'
      break;

    case SearchResultsDetailsReleasesComponetName:
      actionType = 'SET_SELECTED_DETAILS_RELEASES_PAGE'
      break;

    case SearchResultsDetailsRevisionsComponetName:
      actionType = 'SET_SELECTED_DETAILS_REVISIONS_PAGE'
      break;

    case SearchResultsDetailsTorrensCertificatesComponetName:
      actionType = 'SET_SELECTED_DETAILS_TORRENS_CERTIFICATES_PAGE'
      break;

    case AccountsControlPanelKey: // Account List
      actionType = 'SET_SELECTED_ACCOUNTS_PAGE'
      break;

    case AccountSelectionListComponentName: // Account List
      actionType = 'SET_SELECTED_ACCOUNT_SELECTION_PAGE'
      break;

    case UserListComponetName: // User Manager
      actionType = 'SET_SELECTED_USERS_PAGE'
      break;

    case UserSelectionListComponentName: // User Selection Popup
      actionType = 'SET_SELECTED_USER_SELECTION_PAGE'
      break;

    case PlatSeclectionListComponentName: // Plat Finder
      actionType = 'SET_SELECTED_PLAT_SELECTION_PAGE'
      break;

    default:
      break;
  }

  return {
    type: actionType,
    pageNumber
  }
}

export const pageinateSearch = (pageNumber, sort, url, searchCriteria, callback) => async dispatch => {
  if (url.charAt(0) === '/') {
    url = url.substring(1);
  }

  let totalRecordCount = 0;

  let searchCriteriaObject = JSON.parse(searchCriteria);
  searchCriteriaObject.pageNumber = pageNumber;
  searchCriteriaObject.sortDirection = sort.sortDirection;
  searchCriteriaObject.sortKey = sort.sortKey;
  await axios.post(configData.LANDSHARK_SERVER_URL + url, searchCriteriaObject, { withCredentials: true })
    .then((response) => {
      let content = response.data.content;
      if (content.searchResults.totalRecordCount > 0) {
        handleSearchResults(dispatch, content);
      }
      totalRecordCount = response.data.content.searchResults.totalRecordCount;
      dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_PAGE', pageNumber });    // This is the search results table
      callback("OK");
    }, (error) => {
    });

    if (totalRecordCount >= SEARCH_RESULTS_BATCH_SIZE) {
      dispatch(loadTableBatches(searchCriteriaObject, url)); 
    }
};

const handleSearchResults = (dispatch, content) => {
  dispatch({ type: 'UPDATE_SEARCH_RESULTS', searchResults: content.searchResults });    // This is the search results table
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

