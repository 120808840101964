import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { searchByGrantorGranteeBook, setSelectedSearchType, SpecialSearchGrantorGranteeBookKey } from "../../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../constants/constants";
import { validateDates } from "../../../utils/DateValidation";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";

const componentName = SpecialSearchGrantorGranteeBookKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByGrantorGranteeBook: (searchCriteria, history, callback) => dispatch(searchByGrantorGranteeBook(searchCriteria, history, callback)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    grantorGranteeSearchIn: previousSearchKeyValue.grantorGranteeSearchIn ? previousSearchKeyValue.grantorGranteeSearchIn : 'BOTH',
    grantorGranteeLastName: previousSearchKeyValue.grantorGranteeLastName ? previousSearchKeyValue.grantorGranteeLastName : null,
    fromRecordedDate: previousSearchKeyValue.fromRecordedDate ? previousSearchKeyValue.fromRecordedDate : moment('01/01/1900').format('L'),
    toRecordedDate: previousSearchKeyValue.toRecordedDate ? previousSearchKeyValue.toRecordedDate : moment().format('L')
  };
}

class SpecialSearchGrantorGranteeBook extends Component {

  state = {
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    grantorGranteeSearchIn: this.props.grantorGranteeSearchIn,
    grantorGranteeLastName: this.props.grantorGranteeLastName,
    loadingStatus: "",
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByGrantorGranteeBook(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.grantorGranteeLastName) {
      errors["grantorGranteeLastName"] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    this.setState({
      fromRecordedDate: moment('01/01/1900').format('L'),
      toRecordedDate: moment().format('L'),
      grantorGranteeSearchIn: 'BOTH',
      grantorGranteeLastName: '',
      submitMessage: '',
      errors: {}
    })
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Grantor Grantee Book Search",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formGroupRows: [
        {
          formGroups: [
            {
              id: 'grantorGranteeLastName',
              label: 'Business or Last Name',
              fieldType: 'text',
              required: true,
              primaryCriteria: true,
              value: this.state.grantorGranteeLastName
            }
          ]
        },
        {
          formGroups: [
            {
              id: 'grantorGranteeSearchIn',
              label: 'Search In',
              fieldType: 'select',
              options: [{ code: 'BOTH', description: 'Both Grantors & Grantees' }, { code: 'GRANTORS', description: 'Grantors Only' }, { code: 'GRANTEES', description: 'Grantees Only' }],
              value: this.state.grantorGranteeSearchIn
            }
          ]
        },
        {
          formGroups: [{ id: 'fromRecordedDate', label: 'From Date', fieldType: 'date', value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
        },
        {
          formGroups: [{ id: 'toRecordedDate', label: 'To Date', fieldType: 'date', value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
        }
      ],

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return <FormTemplate {...formProps} />
  }


}

export default connect(mapStateToProps, matchDispatchToProps)(SpecialSearchGrantorGranteeBook);
