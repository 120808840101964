import React, { Component } from "react"
import { Container, Col, Row, Table } from 'react-bootstrap'

import { connect } from "react-redux";
import Loading from '../../common/modal/Loading';
import { displayTaxLienDetails } from '../../../redux/actions/searchActions'
import SearchPaginator from '../../common/page/SearchPaginator'
import {getPaginatorHelper} from '../../common/page/PaginatorHelper'
import { LOADING_STATUES } from "../../constants/constants";

import CreditCardSearchAuthWindow from "../../../components/common/form/CreditCardSearchAuthWindow";
import CreditCardPriceListWindow from "../../../components/common/form/CreditCardPriceListWindow";
import CreditCardPriceListReAuthWindow from "../../../components/common/form/CreditCardPriceListReAuthWindow"

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";

export const SearchResultsTableComponetName = 'TaxLienSearchResultsTable';

const mapStateToProps = state => {

    return {
        creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        searchResults: state.searchResultsReducer,
        paginationPage: state.searchResultsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        selectedRow:  state.searchResultsTableSelectedRowReducer,
        rewind: state.searchSequenceRewindReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        select: (id, description, callback) => {
            if (id) {
                dispatch(displayTaxLienDetails(id, description, callback))
            }
        }, // For some reason, id can be null sometimes, so checking to see if id has a value.
        resetRewindSearch: () =>  dispatch({ type: 'RESET_REWIND_SEARCH_SEQUENCE'})
    }
}

class TaxLienSearchResultsTable extends Component {
    state = {
        selectedDocumentId: null,
        selectedDocumentName: '',
        loadingStatus: '',

        token: null,

        creditCardPriceListWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false,
        creditCardPriceListReAuthWindowShow: false
    }

    handleCreditCardPriceListClose = () => {
        this.setState(
            {
                creditCardPriceListWindowShow: false
            });
    }

    handleCreditCardPriceListContinue = () => {
        this.setState(
            {
                creditCardAuthorizeDotNetWindowShow: true
            });
    }

    handleCreditCardAuthorizeDotNetWindowClose = () => {
        this.setState(
            {
                creditCardAuthorizeDotNetWindowShow: false
            });
    }
    
    showLoading = () => {
        if (this.state.loadingStatus === '') {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status, content) => {
        if (content.paymentNeededAgain === true) {
            this.setState({
                loadingStatus: "",
                submitMessage: "",
                creditCardPriceListReAuthWindowShow: true
            })
        } else if (content.paymentNeeded === true) {
            this.setState({
                loadingStatus: "",
                submitMessage: "",
                creditCardPriceListWindowShow: true
            })
        } else if (status === 'ERROR') {
            this.setState({
                loadingStatus: "",
                submitMessage: ""
            })
        } else {
            this.setState({
                loadingStatus: "",
                submitMessage: message,
                creditCardPriceListWindowShow: false,
                creditCardAuthorizeDotNetWindowShow: false,
                creditCardPriceListReAuthWindowShow: false
            })
        }
    }

    handleAuthorizedSubmit = () => {
        this.props.select(this.state.selectedDocumentId, this.state.selectedDocumentName, this.hideLoading);
    }

    handleCreditCardPriceListReAuth = () => {
        this.setState({creditCardAuthorizeDotNetWindowShow:true});
    }
    
    handleCreditCardPriceListReAuthWindowClose = () => {
        this.setState(
            {
                creditCardPriceListReAuthWindowShow: false
            });
    }

    handleRowClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        this.props.resetRewindSearch();
        if (this.showLoading() === true) {
            const selectedDocumentName = event.target.closest("tr").getAttribute("name");
            this.setState({ selectedDocumentId: clickedId, selectedDocumentName });
            this.props.select(clickedId, selectedDocumentName, this.hideLoading);
        }
    }

    componentDidMount() {
        if (this.props.creditCardUser) {
            axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
                .then((response) => {

                    let result = new AjaxResult(response);

                    if (!result.isOk()) {
                        result.handleError();
                    } else {
                        this.setState({ token: result.content().token });
                    }
                }, (error) => {
                    handleError(error, this.props.history);
                })
        }
    }

    render() {
        let loadingLabel = this.props.tableLoading === true ? 'Loading' : "Displaying";
        // apply any inactive filter and text filter
		// let filteredList = this.props.accounts.filter(accountResult => 
        // 	(this.state.includeInactive || accountResult.active) && ( !this.state.filterText || accountResult.name.includes(this.state.filterText)))
        let filteredList = this.props.searchResults.searchResultList;
        const numberOfVisibleRecords = 100;
        let numberOfPages = Math.ceil ( filteredList.length / numberOfVisibleRecords );
		let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);

        let displayResults = paginationHelper.currentPageStartingRecordNumber === paginationHelper.currentPageEndingRecordNumber ? 
                                paginationHelper.currentPageStartingRecordNumber :
                                paginationHelper.currentPageStartingRecordNumber + "-" + paginationHelper.currentPageEndingRecordNumber;

        let truncatedResultWarning = <></>;
        if (filteredList.length >= 1000) {
            truncatedResultWarning = <span className='badge badge-danger'>Results truncated.  If needed, narrow criteria and search again.</span>
        }

        // The authorize.net window in CreditCardSearchAuthWindow needs to re-render every time apparently.  I tried to use the authorize.net token as the component key, but apparently, 
        // the token either is too big or has characteers that didn't work out. Therefore, I'm using a random number to force it re-render each time.
        const creditCardAuthorizeDotNetWindowKey = Math.ceil(Math.random() * 1000000000) + "";

        let  selectedDocumentId = 0;
        if (this.props.rewind === true) {
            selectedDocumentId = this.props.selectedRow;
        } else {
            selectedDocumentId = this.state.selectedDocumentId;
        }
        return (
            <>
                    <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                        <Table responsive striped bordered hover size="sm" style={{ maxHeight: 600, overflowY: 'auto',  display: 'inline-block'}} >
                            <thead style={{ display: 'table', width: '100%',   tableLayout: 'fixed'}}>
                            <tr>
                                <th className="table-td-xs" scope="col" style={{ width: '30%' }}>Instrument<br />Document #</th>
                                <th className="table-td-xs" scope="col" style={{ width: '20%' }}>Recorded Date<br />Lien Date</th>
                                <th className="table-td-xs" scope="col" style={{ width: '30%' }}>Lien #<br />Taxpayers<br /></th>
                                <th className="table-td-xs" scope="col" style={{ width: '20%' }}>Revisions<br />Releases/Revokes</th>
                            </tr>
                            </thead>
                            <tbody key={this.props.paginationPage} style={{ display: 'table', width: '100%', tableLayout: 'fixed', cursor: "pointer"}} >
                                {
                                    paginationHelper.currentPageRecordList.map((searchResult) => {
                                        const selectedClass = selectedDocumentId == searchResult.docId ? "table-active" : ""; // highlight the selected row

                                        let taxpayersBadge = searchResult.taxpayerSearchResults && searchResult.taxpayerSearchResults.length > 1 ? "more" : "";
                                        let taxpayerDisplayName = searchResult.taxpayerSearchResults && searchResult.taxpayerSearchResults.length > 0 && searchResult.taxpayerSearchResults[0] ? searchResult.taxpayerSearchResults[0].name : '';
                                   
                                        let releasesBadge = searchResult.releaseSearchResults && searchResult.releaseSearchResults.length > 1 ? "more" : "";
                                        let hasReleaseOne = searchResult.releaseSearchResults && searchResult.releaseSearchResults.length > 0 && searchResult.releaseSearchResults[0];
                                       
                                        let releaseReovokeOne = '';
                                        if (hasReleaseOne) {
                                            let releaseOne = '--';
                                            let releaseDocNumber = searchResult.releaseSearchResults[0].releaseDisplayDocNumber;
                                            if (releaseDocNumber && releaseDocNumber !== '') {
                                                releaseOne = releaseDocNumber;
                                            }

                                            let revokeOne = '--';
                                            let revokeDocNumber = searchResult.releaseSearchResults[0].revokeDisplayDocNumber;
                                            if (revokeDocNumber && revokeDocNumber !== '') {
                                                revokeOne = revokeDocNumber;
                                            }

                                            releaseReovokeOne = releaseOne + ' / ' + revokeOne; 
                                        }
                                    
                                        let revisionsBadge = searchResult.revisionSearchResults && searchResult.revisionSearchResults.length > 1 ? "more" : "";
                                        let revisionOne = searchResult.revisionSearchResults && searchResult.revisionSearchResults.length > 0 && searchResult.revisionSearchResults[0]  ? searchResult.revisionSearchResults[0].displayDocNumber : '';

                                        return (
                                            <tr className={selectedClass} style={{ cursor: "pointer" }} id={searchResult.docId} name={searchResult.displayDocNumber} onClick={this.handleRowClick}>
                                                <td className="table-td-xs" style={{ width: '30%' }} ><strong>{searchResult.instrumentDescription}</strong><br />{searchResult.displayDocNumber ? searchResult.displayDocNumber : 'Loading'}<br/></td>
                                                <td className="table-td-xs" style={{ width: '20%' }}>{searchResult.recordedDate}<br/>{searchResult.lienDate}</td>
                                                <td className="table-td-xs" style={{ width: '30%' }}>{searchResult.lienNumber}<br/>{taxpayerDisplayName}&nbsp;<span className='badge badge-light'>{taxpayersBadge}</span></td>
                                                <td className="table-td-xs" style={{ width: '20%' }}>{revisionOne}&nbsp;<span className='badge badge-light'>{revisionsBadge}</span><br/>{releaseReovokeOne}&nbsp;<span className='badge badge-light'>{releasesBadge}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col style={{ marginLeft: 4}} >{loadingLabel} Results: {displayResults} of {filteredList.length} {truncatedResultWarning}</Col>
                        </Row>
                        <Row>
                            <Col style={{ marginLeft: 4}} ><SearchPaginator style={{ justifyContent: 'center' }} componentName={SearchResultsTableComponetName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></SearchPaginator></Col>
                        </Row>
                    </Container>
                 
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <CreditCardPriceListWindow show={this.state.creditCardPriceListWindowShow} handleContinueClicked={this.handleCreditCardPriceListContinue} handleCloseClicked={this.handleCreditCardPriceListClose} />
                <CreditCardSearchAuthWindow  key={creditCardAuthorizeDotNetWindowKey}  show={this.state.creditCardAuthorizeDotNetWindowShow} handleAuthorizedSubmit={this.handleAuthorizedSubmit} handleCloseClicked={this.handleCreditCardAuthorizeDotNetWindowClose} token={this.state.token} />
                <CreditCardPriceListReAuthWindow show={this.state.creditCardPriceListReAuthWindowShow} handleContinueClicked={this.handleCreditCardPriceListReAuth} handleCloseClicked={this.handleCreditCardPriceListReAuthWindowClose} />

            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxLienSearchResultsTable);