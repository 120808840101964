import React, { Component } from "react"
import { Container, Col, Row, Table } from 'react-bootstrap'

import { connect } from "react-redux";

import { displayTractBookDetails } from '../../../redux/actions/searchActions'
import SearchPaginator from '../../common/page/SearchPaginator'
import {getPaginatorHelper} from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import { LOADING_STATUES } from "../../constants/constants";
const componentName = 'SearchResultsTable';

const mapStateToProps = state => {

    return {
        searchResults: state.searchResultsReducer,
        paginationPage: state.searchResultsPaginationReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        select: (id, description, callback) => {
            if (id) {
                dispatch(displayTractBookDetails(id, description, callback))
            }
        } // For some reason, id can be null sometimes, so checking to see if id has a value.
    }
}

class TractBookSearchResultsTable extends Component {
    state = {
        selectedId: null,
        loadingStatus: ''
    }

    showLoading = () => {
        if (this.state.loadingStatus === '') {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }
        this.setState({
            loadingStatus: ""
        })
    }

    handleRowClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        if (this.showLoading() === true) {
            this.setState({ selectedId: clickedId })
            this.props.select(clickedId, event.target.closest("tr").getAttribute("name"), this.hideLoading);
        }
    }

    render() {

        let filteredList = this.props.searchResults.searchResultList;
        const numberOfVisibleRecords = 100;
        let numberOfPages = Math.ceil ( filteredList.length / numberOfVisibleRecords );
		let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);

        let displayResults = paginationHelper.currentPageStartingRecordNumber === paginationHelper.currentPageEndingRecordNumber ? 
                                paginationHelper.currentPageStartingRecordNumber :
                                paginationHelper.currentPageStartingRecordNumber + "-" + paginationHelper.currentPageEndingRecordNumber;

        return (
            <>
        
            <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                    <Table responsive striped bordered hover size="sm" style={{ maxHeight: 600, overflowY: 'auto',  display: 'inline-block'}} >
                        <thead style={{ display: 'table', width: '100%',   tableLayout: 'fixed'}}>
                            <tr>
                                <th className="table-td-xs" scope="col" style={{ width: '20%' }}>Tract Book ID<br />Recorded Date</th>
                                <th className="table-td-xs" scope="col" style={{ width: '80%' }}>Legal<br />&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody key={this.props.paginationPage} style={{ display: 'table', width: '100%', tableLayout: 'fixed', cursor: "pointer"}} >
                            {
                                paginationHelper.currentPageRecordList.map((searchResult) => {
                                    const legalDescriptionsBadge = searchResult.legalSearchResults && searchResult.legalSearchResults.length > 1 ? "more" : "";
                                    const legalDescriptionOne = searchResult.legalSearchResults && searchResult.legalSearchResults.length > 0 && searchResult.legalSearchResults[0] ? searchResult.legalSearchResults[0].description : '';

                                    let selectedClass = this.state.selectedId == searchResult.docId ? "table-active" : ""; // highlight the selected row
                                    return (
                                        <tr className={selectedClass} style={{ cursor: "pointer" }} id={searchResult.docId} name={searchResult.docNumber} onClick={this.handleRowClick}>
                                            <td className="table-td-xs" style={{ width: '20%' }} ><strong>{searchResult.docNumber}</strong><br />{searchResult.recordedDate}<br/></td>
                                            <td className="table-td-xs" style={{ width: '80%' }} >{legalDescriptionOne}&nbsp;<span className='badge badge-light'>{legalDescriptionsBadge}</span></td>
                                      </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row>
                        <Col style={{ marginLeft: 4}} ><SearchPaginator style={{justifyContent: 'center'}} componentName={componentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></SearchPaginator></Col>
					</Row>
                    <Row>
                        <Col style={{ marginLeft: 4}} >Displaying Results: {displayResults} of {filteredList.length}</Col>
                    </Row>
                </Container>
        
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TractBookSearchResultsTable);