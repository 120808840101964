import React, { Component } from "react"
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from "react-redux";

import PageBanner from "../../common/page/PageBanner";
import CountyNotes from "../../common/page/CountyNotes";

import SelectField from "../../common/form/SelectField";

import TaxLienSearchResultsDetails from "./TaxLienSearchResultsDetails";
import TaxLienSearchResultsTable from "./TaxLienSearchResultsTable";

import { caretSquareDownIcon, caretSquareUpIcon, infoSmallIcon, backwardSmallIcon } from "../../../img/font_awesome/font_awesome"

import { searchResultsSort, displayTaxLienDetails, searchByTaxLien } from "../../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../constants/constants";
import Loading from '../../common/modal/Loading';

import NotableDates from '../../home/NotableDates';

const matchDispatchToProps = (dispatch) => {
    return { 
      searchResultsSort: (sort, currentSearch, history, callback) => dispatch(searchResultsSort(sort, currentSearch, history, callback)),
      searchByTaxLien: (searchCriteria, history, callback) => dispatch(searchByTaxLien(searchCriteria, history, callback)),
      selectDetail: (id, description, callback) => dispatch(displayTaxLienDetails(id, description, callback)),
      resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
      rewindSearch: () =>  dispatch({ type: 'REWIND_SEARCH_SEQUENCE'}),
      paginate: (pageNumber) => dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_PAGE', pageNumber })
    }
}

const mapStateToProps = state => {
    return {
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        sideNavigationOpen: state.sideNavigationReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        searchResults: state.searchResultsReducer,
        pageNumber: state.searchResultsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        sortKey: state.searchResultsSortReducer.sortKey,
        notableDates: state.notableDatesReducer,
        searchSequence: state.searchSequenceStartedReducer,
        searchSequenceSelectedDocumentId: state.searchResultsTableSelectedRowReducer  
    };
}

class TaxLienSearchResults extends Component {

    state = {
        searchResultsSort: 'docNumber',   // Date is the default sort
        searchResultsSortDirection: 'ASC',
        showNotableDatesModal: false,
        notableDatesLeft: 600,
        notableDates: {},
        loadingStatus: ""
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
    }

    handleChange = (event) => {
        this.setState(
            {
                searchResultsSort: event.target.value
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleClick = () => {
        this.setState(
            {
                searchResultsSortDirection: this.state.searchResultsSortDirection === 'ASC'? 'DESC' : 'ASC'
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleSort = () => {
        if (this.showLoading() === true) {
            const { loadingStatus, ...request } = this.state; // remove UI only fields before submitting
            const request1 = Object.assign(request, { pageNumber: this.props.pageNumber });
		    this.props.searchResultsSort(request1, this.props.currentSearch, this.props.history, this.hideLoading);
        }
	}

    showOriginalResultList = (event) => {
        event.preventDefault();

        let request = {}
        this.props.searchSequence.displayCriteria.forEach((criteria) => {
            request[criteria.key] = criteria.value;
        })

        request.updateHistory = true;
        request.sortKey = this.props.searchSequence.sortKey; 
        request.sortDirection = this.props.searchSequence.sortDirection;
        
        if (this.showLoading() === true) {
            if (this.props.searchSequence.pageNumber > 1) {
                request.pageNumber = this.props.searchSequence.pageNumber;
                this.props.paginate(request.pageNumber);
            }

            this.props.resetSearchSequence();
            this.props.rewindSearch();
            this.props.searchByTaxLien(request, this.props.history, this.selectDetail);  
        }
    }

    selectDetail = () => {
        this.props.selectDetail(this.props.searchSequenceSelectedDocumentId, '', this.hideLoading);
    }

    showNotableDates = (event) => {
        event.preventDefault();
        let notableDatesLeft = Math.ceil(event.clientX / 3);
        this.setState({notableDatesLeft, showNotableDatesModal : true});
    }

    hideNotableDates = (event) => {
        event.preventDefault();
        this.setState({showNotableDatesModal : false});
    }
    
    render() {
        let formTemplateMarginLeft = 60
        if (this.props.sideNavigationOpen) {
            formTemplateMarginLeft = 270
        }

        let buttonIcon = caretSquareDownIcon;
        if (this.state.searchResultsSortDirection === 'ASC') {
          buttonIcon = caretSquareUpIcon;
        }

        let sortOptions = [{ code: "docNumber", description: "Document Number"}, {code: "recordedDate", description: "Recorded Date"},  {code: "lienDate", description: "Lien Date"},  {code: "lienNumber", description: "Lien Number"}];
		sortOptions.sort((a, b) => 
			{ 
				if (a.description > b.description) {
					return 1;
			  	} else if (a.description < b.description) {
					return -1;
			  	}
			  	return 0;
			}
		)

        let sortByFormGroup =  { layout: 'horizontal', id: 'searchResutlsSort', label: 'Sort by', fieldType: 'select',  value: this.props.sortKey, size: "sm", options: sortOptions }
        
        let currentSearchLongDescription = this.props.currentSearch ? this.props.currentSearch.longDescription : ""
        let currentSearchCriteria = this.props.currentSearch ? this.props.currentSearch.displayCriteria : []
        let selectButton =  <Button variant="primary" size="sm" onClick={this.handleClick}> {buttonIcon} </Button>

        let pageButtons = [];
        if (this.props.searchSequence !==  null) {
            pageButtons.push({ label: 'Back to Search Results', icon:backwardSmallIcon, onClickAction: this.showOriginalResultList, className: 'btn btn-secondary btn-sm' });
        }
        pageButtons.push({ label: 'Notable Dates', icon:infoSmallIcon, onClickAction: this.showNotableDates, className: 'btn btn-secondary btn-sm' });

        return (
            <div style={{ marginTop: 4, marginLeft: formTemplateMarginLeft, marginRight: 20, marginBottom: 4,  paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <CountyNotes />
                <PageBanner pageHeading={currentSearchLongDescription} searchCriteria={currentSearchCriteria}  buttons={pageButtons}   />
                <Container fluid="true" >
                    <Row style={{marginRight: 1}}>
                        <Col lg={7} style={{paddingRight: 0}}>                   
                            <SelectField size="sm" formGroup={sortByFormGroup} selectButton={selectButton} onChange={this.handleChange} disabled={this.props.tableLoading}/>
                            <TaxLienSearchResultsTable />
                        </Col>
                   
                        <Col lg={5} style={{paddingTop: 2, paddingBottom: 4}}>
                            <TaxLienSearchResultsDetails handleImageClick={this.handleImageClick} history={this.props.history}/>
                        </Col>
                    </Row>
                </Container>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <Modal show={this.state.showNotableDatesModal} onHide={this.hideNotableDates} style={{ left: this.state.notableDatesLeft, top: 0}} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body style={{ background: '#3674AB', color: 'white', padding: 0 }}>
                        <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideNotableDates}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TaxLienSearchResults);