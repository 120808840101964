import React, { Component } from "react"
import { Container, Col, Row, Table } from 'react-bootstrap'

import { connect } from "react-redux";

import { displayTorrensCertificateDetails } from '../../../redux/actions/searchActions'
import SearchPaginator from '../../common/page/SearchPaginator'
import {getPaginatorHelper} from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import { LOADING_STATUES } from "../../constants/constants";

const componentName = 'SearchResultsTable';

const mapStateToProps = state => {

    return {
        isTorrensUseFileNumbers : state.landlinkConfigReducer.torrensUseFileNumber,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        searchResults: state.searchResultsReducer,
        paginationPage: state.searchResultsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        selectedRow:  state.searchResultsTableSelectedRowReducer,
        rewind: state.searchSequenceRewindReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        select: (id, description, callback) => {
            if (id) {
                dispatch(displayTorrensCertificateDetails(id, description, callback))
            }
        }, // For some reason, id can be null sometimes, so checking to see if id has a value.
        resetRewindSearch: () =>  dispatch({ type: 'RESET_REWIND_SEARCH_SEQUENCE'})
    }
}

class TorrensSearchResultsTable extends Component {
    state = {
        selectedId: null,
        loadingStatus: ''
    }

    showLoading = () => {
        if (this.state.loadingStatus === '') {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }
        this.setState({
            loadingStatus: ""
        })
    }

    handleRowClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        this.props.resetRewindSearch();
        if (this.showLoading() === true) {
            this.setState({ selectedId: clickedId })
            this.props.select(clickedId, event.target.closest("tr").getAttribute("name"), this.hideLoading);
        }
    }

    render() {
        let certificateNumberFileNumberHeader =  this.props.isTorrensUseFileNumbers === true ? <th className="table-td-xs" scope="col" style={{ width: '34%' }}>Certificate #<br />File #</th> : <th className="table-td-xs" scope="col" style={{ width: '34%' }}>Certificate #<br />&nbsp;</th> ;
        // apply any inactive filter and text filter
		// let filteredList = this.props.accounts.filter(accountResult => 
        // 	(this.state.includeInactive || accountResult.active) && ( !this.state.filterText || accountResult.name.includes(this.state.filterText)))
        let filteredList = this.props.searchResults.searchResultList;
        const numberOfVisibleRecords = 100;
        let numberOfPages = Math.ceil ( filteredList.length / numberOfVisibleRecords );
		let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);

        let displayResults = paginationHelper.currentPageStartingRecordNumber === paginationHelper.currentPageEndingRecordNumber ? 
                                paginationHelper.currentPageStartingRecordNumber :
                                paginationHelper.currentPageStartingRecordNumber + "-" + paginationHelper.currentPageEndingRecordNumber;

        let  selectedId = 0;
        if (this.props.rewind === true) {
            selectedId = this.props.selectedRow;
        } else {
            selectedId = this.state.selectedId;
        }

        return (
            <>
           
           <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
            <Table responsive striped bordered hover size="sm" style={{ maxHeight: 600, overflowY: 'auto',  display: 'inline-block'}} >
                        <thead style={{ display: 'table', width: '100%',   tableLayout: 'fixed'}}>
                            <tr>
                                {certificateNumberFileNumberHeader}
                                <th className="table-td-xs" scope="col" style={{ width: '33%' }}>Certified Date<br/> {this.props.bookLabel}/Page</th>
                                <th className="table-td-xs" scope="col" style={{ width: '33%' }}>Status<br />&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody key={this.props.paginationPage} style={{ display: 'table', width: '100%', tableLayout: 'fixed', cursor: "pointer"}} >
                            {
                                paginationHelper.currentPageRecordList.map((searchResult) => {
                                    let selectedClass = selectedId == searchResult.id ? "table-active" : ""; // highlight the selected row

                                    // The status can be Certified, In Progess or Cancelled, but only Cancelled may have more than 1.  
                                    const statusBadge = searchResult.cancelledBySearchResults && searchResult.cancelledBySearchResults.length > 1 ? "more" : "";    
                                    return (
                                        <tr className={selectedClass} style={{ cursor: "pointer" }} id={searchResult.id} name={searchResult.certificateNumber} onClick={this.handleRowClick}>
                                            <td className="table-td-xs" style={{ width: '34%' }} ><strong>{searchResult.certificateNumber} <span className='badge badge-light'>{searchResult.shortStatus}</span> </strong><br /> {searchResult.fileNumber}  </td>
                                            <td className="table-td-xs" style={{ width: '33%' }} >{searchResult.certifiedDate}<br/>{searchResult.displayBookAndPage}</td>
                                            <td className="table-td-xs" style={{ width: '33%' }} >{searchResult.status}&nbsp;<span className='badge badge-light'>{statusBadge}</span><br/>&nbsp;</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row>
                        <Col style={{ marginLeft: 4}} ><SearchPaginator style={{justifyContent: 'center'}} componentName={componentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></SearchPaginator></Col>
					</Row>
                    <Row>
                        <Col style={{ marginLeft: 4}} >Displaying Results: {displayResults} of {filteredList.length}</Col>
                    </Row>

                </Container>
           
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TorrensSearchResultsTable);