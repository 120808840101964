import React, { Component } from "react"
import { Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from "react-redux";

import PageBanner from "../../common/page/PageBanner";
import CountyNotes from "../../common/page/CountyNotes";

import SelectField from "../../common/form/SelectField";

import TractBookSearchResultsDetails from "./TractBookSearchResultsDetails";
import TractBookSearchResultsTable from "./TractBookSearchResultsTable";

import { caretSquareDownIcon, caretSquareUpIcon, fileDownloadSmallIcon } from "../../../img/font_awesome/font_awesome"

import { searchResultsSort, searchResultsPrint } from "../../../redux/actions/searchActions";

import { LOADING_STATUES } from "../../constants/constants";
import Loading from '../../common/modal/Loading';

const matchDispatchToProps = (dispatch) => {
    return { 
      searchResultsSort: (sort, currentSearch, history, callback) => dispatch(searchResultsSort(sort, currentSearch, history, callback)),
      searchResultsPrint: (sort, currentSearch, history, callback) => dispatch(searchResultsPrint(sort, currentSearch, history, callback))
    }
}

const mapStateToProps = state => {
    return {
        sideNavigationOpen: state.sideNavigationReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        searchResults: state.searchResultsReducer
    };
}

class TractBookSearchResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResultsSort: 'id',   // Reference ID is the default sort
            searchResultsSortDirection: 'ASC',
            loadingStatus: ""
        }    
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
      }

    handleChange = (event) => {
        this.setState(
            {
                searchResultsSort: event.target.value
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleClick = () => {
        this.setState(
            {
                searchResultsSortDirection: this.state.searchResultsSortDirection === 'ASC'? 'DESC' : 'ASC'
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleSort = () => {
        if (this.showLoading() === true) {
            const { loadingStatus, imagingWindows, ...request } = this.state; // remove UI only fields before submitting
		    this.props.searchResultsSort(request, this.props.currentSearch, this.props.history, this.hideLoading);
        }
	}

    printResults = (event) => {
        event.preventDefault();
        if (this.showLoading() === true) {
            const { loadingStatus, imagingWindows, alertWindow, ...request } = this.state; // remove UI only fields before submitting
            const request1 = Object.assign(request, { pageNumber: -1 });
		    this.props.searchResultsPrint(request1, this.props.currentSearch, this.props.history, this.hideLoading);
        }
    }

    render() {
        let formTemplateMarginLeft = 60;
        if (this.props.sideNavigationOpen) {
            formTemplateMarginLeft = 270;
        }

        let buttonIcon = caretSquareDownIcon;
        if (this.state.searchResultsSortDirection === 'ASC') {
          buttonIcon = caretSquareUpIcon;
        }

        let sortByFormGroup =  { layout: 'horizontal', id: 'searchResutlsSort', label: 'Sort by', fieldType: 'select', value: "N", size: "sm", options: [{ code: "docNumber", description: "Tract Book ID"}, {code: "recordedDate", description: "Recorded Date"}] }

        let currentSearchLongDescription = this.props.currentSearch ? this.props.currentSearch.longDescription : ""
        let currentSearchCriteria = this.props.currentSearch ? this.props.currentSearch.displayCriteria : []
        let selectButton =  <Button variant="primary" size="sm" onClick={this.handleClick}> {buttonIcon} </Button>

        return (
            <div style={{ marginTop: 4, marginLeft: formTemplateMarginLeft, marginRight: 20, marginBottom: 4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <CountyNotes />
                <PageBanner pageHeading={currentSearchLongDescription} searchCriteria={currentSearchCriteria} />

                <Container fluid="true" >
                    <Row style={{marginRight: 1}}>
                        <Col lg={7} style={{paddingRight: 0}}>
                            <SelectField  size="sm"  formGroup={sortByFormGroup} selectButton={selectButton} onChange={this.handleChange} />
                            <TractBookSearchResultsTable />
                        </Col>

                        <Col lg={5} style={{paddingTop: 2, paddingBottom: 4}}>
                            <TractBookSearchResultsDetails history={this.props.history}/>
                        </Col>
                    </Row>
    
                </Container>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            </div>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(TractBookSearchResults);